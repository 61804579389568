.App {
  text-align: center;
}

.ui.icon.header {
  color: #4377b5;
}

.column {
  margin-bottom: 40px !important;
}

nav.bg-custom-transparent {
  background-color: rgb(255, 255, 255) !important;
}

h2.ui.icon.header {
  align-items: center;
  justify-content: center;
  display: grid;
}

button.btn-link {
  text-decoration: none;
}

.react-parallax-bgimage {
  top: -250px;
}

@media screen and (min-width: 350) {
  .react-parallax-bgimage {
    top: -400px;
  }
}

.logo-welcome {
    margin-left: -10px;
}

.less-space {
  margin-top: -5px !important;
}

.welcome-text h5 {
  color: black;
}

p {
  color: black;
}

.nav-link {
  color: black !important;
}

@media screen and (min-width: 350px) {
  .justify-end {
      padding-left: 78% !important;
  }

}

@media screen and (min-width: 350px) {
  div#menu-mobile {
      height: 300px;
  }
}

img#ca-logo {
  width: 50%;
  margin-left: 0;
  margin-top: 5px;
}

img#tax-logo {
  width: 30%;
  margin-left: 0;
}

img#mancala-logo {
  width: 50%;
  margin-left: 0;
  margin-top: 12%;
}

/*
@media screen and (max-width: 800px) {
  img#mancala-logo {
    width: 50%;
    margin-left: 75%;
    margin-top: 12%;
  }
}
*/

@media screen and (max-width: 400px) {
  img#ca-logo {
      width: 100%;
  }

  img#tax-logo {
      width: 60%;
  }

  img#mancala-logo {
      margin-top: 0;
      margin-left: 0;
      margin-right: 10px;
  }
}

@media screen and (max-width: 700px) {
  img#ca-logo {
      width: 100%;
  }

  img#tax-logo {
      width: 60%;
  }

  img#mancala-logo {
      margin-top: 0;
      margin-left: 0;
      margin-right: 10px;
  }
}

@media screen and (min-width: 700px) {
  img#mancala-logo {
    width: 50%;
    margin-left: 75%;
    margin-top: 12%;
  }
}

@media screen and (min-width: 1000px) {
  img#mancala-logo {
    margin-left: 0 !important;
  }
}

blockquote {
  font-size: 1em !important;
}

cite {
  font-size: 1.3em !important;
}

div#reviews {
  height: 100%;
}

div#about {
  height: 100%;
}

h1 {
  color: #20ba97 !important;
}

@media screen and (max-width: 700px) {
  img#tax-agent-logo {
    width: 40%;
    height: auto;
  }
}

@media screen and (min-width: 700px) {
  img#ca-logo {
    width: 55%;
    height: auto;
  }

  img#tax-logo {
    width: 100%;
    height: auto;
    margin-top: -3rem;
  }

  img#tax-agent-logo {
    width: 25%;
    height: auto;
    margin-top: 1rem;
  }
}